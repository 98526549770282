

 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
 }

 html,
 body {
    text-align: center;
        height: 100%;
        width: 100%;
        background: #fffffe;
 }

 .text{
    font-size: 1.25rem;
    color: #2D3A48;
    font-weight: 500;
    line-height: 24px;
    text-transform: capitalize
 };