.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.4rem;
  width: 55%;
  height: 12rem;
  padding: 3em 1em;

  button {
    width: 70%;
    border-radius: 0.25rem;
    padding-inline: 1rem;
    padding-block: 0.75rem;
    box-shadow: none;
    background-color: #069ba2;
    color: #edf4f4;
    cursor: pointer;
    font-size: 0.8rem;
    border: 1px solid #cfd9e2;
    margin: 0;
    padding: 0.5rem;
  }
}

.successMsg {
  font-size: 1.25rem;
  color: #2d3a48;
  font-weight: 500;
  line-height: 24px;
  text-transform: capitalize;
}
