.container {
  margin-top: 2rem;
  justify-content: center;
  display: flex;
  gap: 1rem;

  img {
    height: 24px;
  }
}
